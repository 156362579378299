import http from './http'
class ApiKeys {
  list() {
    return http.get(`/parceiro/api_keys`)
  }
  
  create() {
    return http.post(`/parceiro/api_keys`, {})
  }
  
  disable(id) {
    return http.post(`/parceiro/api_keys/${id}/disable`, {})
  }
}
export default new ApiKeys()
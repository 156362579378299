<template>
  <div>
    <b-card>
      <div class="row mb-2">
        <div class="col-md-6">
          <h3 class="mb-1">Suas chaves de API</h3>
        </div>
        <div class="col-md-6 d-flex justify-content-end">
          <b-button variant="primary btn-secondary color-white" @click="createKey">
            <span class="mb-0 text-dark font-weight-bolder"> Solicitar Nova Chave </span>
          </b-button>
        </div>
      </div>
      <b-table :items="keys" :fields="fields" class="border">
        <template #cell(actions)="data">
          <div>
            <feather-icon
              v-b-tooltip.hover
              icon="DeleteIcon"
              class="cursor-pointer"
              size="28"
              title="Desativar chave"
              @click="disableKey(data.item.id)"
            />
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import ApiKeys from '@/services/api_keys'
import { BRow, BCol, BImg, BCard, BButton, BTable } from 'bootstrap-vue'
import {
  dateTimeFormatter,
} from '@core/comp-functions/data_visualization/datatable'
export default {
  name: 'ApiKeys',
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BTable,
    BButton,
  },
  data() {
    return {
      fields: [
        { key: 'access_key_id', label: 'Access Key ID'},
        { key: 'created_at', label: 'Criada em', formatter: dateTimeFormatter },
        { key: 'last_access', label: 'Último acesso', formatter: dateTimeFormatter },
        { key: 'actions', label: 'Ações' },
      ],
      keys: [],
      createdAccessKeyId: '',
      createdSecretKey: '',
    }
  },
  async mounted() {
    await this.getUserKeys()
  },
  methods: {
    async getUserKeys() {
      try {
        const { data } = await ApiKeys.list()
        this.keys = data.data
      } catch (error) {
        console.log(error)
      }
    },
    async createKey() {
      this.$swal({
        title: 'Deseja realmente solicitar uma nova Chave de API?',
        html: `Ao confirmar, uma nova chave será gerada`,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Gerar nova chave',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.value) {
          try {
            const { data } = await ApiKeys.create()
            this.createdAccessKeyId = data.data.access_key_id
            this.createdSecretKey = data.data.secret_key
            await this.getUserKeys()
            this.$swal({
              title: 'Chave de API criada com sucesso!',
              html: `
                <span class="alert alert-danger">Armazene sua <strong>Secret Key</strong> em um local seguro, pois ela não será exibida novamente.</span><br><br>
                <strong>Access Key ID:</strong> ${this.createdAccessKeyId}<br></strong><br>
                <strong>Secret Key:</strong> ${this.createdSecretKey}<br>
              `,
              icon: 'success',
              showCloseButton: true,
              showCancelButton: true,
              confirmButtonText: 'Copiar Chave',
              cancelButtonText: 'Fechar',
            }).then((result) => {
              if (result.value) {
                navigator.clipboard.writeText(this.createdSecretKey)
                this.$swal('Secret Key copiada!', '', 'success')
              }
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    async disableKey(id){
      this.$swal({
        title: 'Deseja realmente desativar esta Chave de API?',
        html: `Ao confirmar, a chave será desativada e não poderá mais ser utilizada`,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Desativar chave',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.value) {
          try {
            await ApiKeys.disable(id)
            await this.getUserKeys()
            this.$swal('Chave de API desativada com sucesso!', '', 'success')
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
  },
}
</script>